import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'

const List = () => import('./views/List')

const ROUTE_NAME = 'profile:auto-application:'

const p = name => ROUTE_NAME + name

const LIST = p('list')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: accessPermission(['RESERVATION:AUTO_APPLICATION:VIEW'], options),
    }),
    { path: '*', redirect: { name: LIST } },
  ]
}
