<template lang="pug">
  include /mixins
  +b.i-panel.--default
    +b.ds-panel.--space_sm
      +e.element.--offset_bottom
        +b.g-row.--justify_between
          +b.g-cell
            +b.P.ds-caption.--size_3xs.--color_dark-grey {{ filter.label }}
          +b.g-cell
            +b.P.ds-caption.--size_3xs.--color_dark-grey(v-if="filter.initial.isPrice") {{ internal[0]|prettifyPrice }} — {{ internal[1]|prettifyPrice }} {{ filter.initial.unit }}
            +b.P.ds-caption.--size_3xs.--color_dark-grey(v-else) {{ internal[0] }} — {{ internal[1] }} {{ filter.initial.unit }}
    range-slider(
      v-model="internal"
      class="vue-slider--variant_default"
      :clickable="false"
      :drag-on-click="true"
      transition="0.2"
      :height="3"
      :min="min"
      :max="max"
      :interval="interval"
      @drag-end="debounceUpdate"
    )
    +b.ds-panel.--space_md
      +e.element.--offset_top
        +b.g-row
          +b.g-cell.g-cols.--6-xs
            d-control-static-input(
              v-model="internal[0]"
              input-class="d-control-input--variant_5"
              name="min"
              type="number"
              :input-label="labels.to"
              @input="debounceUpdate"
            )
          +b.g-cell.g-cols.--6-xs
            d-control-static-input(
              v-model="internal[1]"
              input-class="d-control-input--variant_5"
              name="max"
              type="number"
              :input-label="labels.from"
              @input="debounceUpdate"
            )
</template>

<script>
import { debounce } from 'lodash'

/* eslint-disable */
const gcd = (a, b, onZero = 1) => (
  a < b
    ? gcd(b, a, onZero)
    : (
      b < 0.001 || isNaN(b) || isNaN(b)
        ? (0 === a ? onZero : a)
        : gcd(b, a - Math.floor(a / b) * b)
    )
)

export default {
  name: 'filter-slider-widget',
  props: ['value', 'filter', 'placeholder'],

  data() {
    return {
      debounceUpdate: () => {},
      internal: [0, 1],
      interval: 1,
      min: 0,
      max: 1,
      labels: {
        to: this._('от'),
        from: this._('до'),
      },
    }
  },

  mounted() {
    const timeout = 200
    /**
     * add debounce wrapper for prevent multiple requests
     */
    this.debounceUpdate = debounce(this.update, timeout)
  },

  watch: {
    filter: { deep: true, immediate: true, handler: 'clearMinMax' },
    value: { immediate: true, handler: 'updateInternal' },
  },

  methods: {
    clearMinMax() {
      const { minValue, maxValue } = this.filter.initial
      const min = Math.max(minValue || 0)
      const max = Math.min(maxValue || 0)
      if (['rental_yield', 'net_income'].includes(this.filter.urlKey)) {
        this.interval = 1
        this.min = parseInt(Math.ceil(min))
        this.max = parseInt(Math.ceil(max))
      } else {
        this.min = parseFloat(min.toFixed(2))
        this.max = parseFloat(max.toFixed(2))

        const interval = parseFloat(gcd(Math.abs(this.min), Math.abs(this.max)).toFixed(2))

        this.interval = interval > 1 ? 1 : interval
      }

      this.updateInternal()
    },

    updateInternal(value = this.value) {
      if (value) {
        value = value.split('-')
      }
      this.internal = this.normalize(value)
    },

    update() {
      const value = this.clear(this.internal)

      if (value) {
        this.input(value.join('-'))
      } else {
        this.input(value)
      }
    },

    input(value) {
      this.$emit('input', value)
    },

    clear(value) {
      let [min, max] = value
      const [minVal, maxVal] = this.normalize([min, max].join('-'))

      min = minVal
      max = maxVal

      if (Number(min) === this.min && Number(max) === this.max) {
        return undefined
      }

      let result = []

      const notMin = min !== this.min
      const notMax = max !== this.max

      if (notMin) {
        result.push(min)
      } else {
        result.push(0)
      }

      if (notMax) {
        result.push(max)
      } else {
        result.push(this.max)
      }

      if ((result[0] || 0) < this.min) {
        result[0] = this.min
      }

      if ((result[1] || 0) > this.max) {
        result[1] = this.max
      }

      return result
    },

    normalize(value) {
      if (!value) {
        return [this.min, this.max]
      }

      const val = Array.isArray(value) ? value : value.split('-')

      const [min, max] = val

      if ((min || 0) < this.min) {
        this.internal[0] = this.min
      }

      if ((max || 0) > this.max) {
        this.internal[1] = this.max
      }

      return [min, max]
    },
  },
};
</script>
